import { createStore } from "vuex"
import Reservation from "@/classes/reservation"
import axios from "axios"
import route from "ziggy"

const store = createStore({
  state() {
    return {
      // Booking
      booking: {},
      tags: [],
      owner: {},
      info: [],
      notifications: [],
      country: {},
      reminderDate: null,

      // Contracts
      contracts: [],

      // Contacts
      contacts: [],

      // Reservations
      reservations: [],

      // Finance
      billingDocuments: [],
      financeData: {},
      hasSavedCards: false,

      // Notes
      notes: [],

      //  Logs
      logs: [],

      // Reference
      brand: {},
      activities: [],
      venues: [],
      packages: [],
      users: [],
      billingStatuses: [],
      salesStages: [],
      outcomes: [],
      closePercentages: [],
      contractStatuses: [],
      budgets: [],
      lostReasons: [],
      priceChangeReasons: [],
      bookingSources: [],
      durations: [],
      billingTransactionMethods: [],
      contractTemplates: [],
      emailTemplates: [],
      statuses: [],
      contactTypes: [],
      states: [],
      occasions: [],
      taxRates: [],
      notificationTypes: [],
      bookingTags: [],
      transactionalEmails: [],

      // Other
      isProcessing: false,
      bookingFilters: {},
      user: {
        id: null,
      },
    }
  },
  getters: {
    isEditable: (state) => () => {
      for (const reservation of state.reservations) {
        if (reservation.isEditable() === true) {
          return true
        }
      }
      return false
    },

    canBeFinalised: (state) => () => {
      for (const reservation of state.reservations) {
        if (reservation.canBeFinalised()) {
          return true
        }
      }
      return false
    },

    isBookingOwner(state) {
      return state.booking && state.booking.user_id === state.user.id
    },
    isWaitingForPayment(state) {
      return state.financeData.totalDue > 0
    },
    isSalesService(state) {
      return state.booking && state.booking.is_sales_service
    },
    isSelfService(state, getters) {
      return !getters.isSalesService
    },
    hasNotifications(state) {
      return state.notifications.length > 0
    },
    isTaken(state) {
      return state.booking && state.booking.user_id !== null
    },
    timeZone() {
      return new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    activities(state, getters) {
      const validServes = getters.isSalesService ? ["sales", "self"] : ["self"]
      return state.activities.filter((a) => validServes.includes(a.serve))
    },
    contracts(state) {
      return state.booking.contracts
    },
    hasContracts(state) {
      return state.booking.contracts.length > 0
    },
    hasBillingDocuments(state) {
      return state.billingDocuments.length > 0
    },
    transactionalEmailsForReservation(state) {
      const validCodes = ["customer_password_reset", "reservation_amend_link", "reservation_confirmation"]
      return state.transactionalEmails.filter((a) => validCodes.includes(a.code))
    },
    transactionalEmailsForInvoice(state) {
      const validCodes = ["booking_pay_invoice"]
      return state.transactionalEmails.filter((a) => validCodes.includes(a.code))
    },
  },
  mutations: {
    booking(state, value) {
      state.booking = value
    },
    tags(state, value) {
      state.tags = value
    },
    owner(state, value) {
      state.owner = value
    },
    info(state, value) {
      state.info = value
    },
    notifications(state, value) {
      state.notifications = value
    },
    notes(state, value) {
      state.notes = value
    },
    billingDocuments(state, value) {
      state.billingDocuments = value
    },
    financeData(state, value) {
      state.financeData = value
    },
    hasSavedCards(state, value) {
      state.hasSavedCards = value
    },
    contracts(state, value) {
      state.contracts = value
    },
    contacts(state, value) {
      state.contacts = value
    },
    country(state, value) {
      state.country = value
    },
    brand(state, value) {
      state.brand = value
    },
    activities(state, value) {
      state.activities = value
    },
    venues(state, value) {
      state.venues = value
    },
    billingStatuses(state, value) {
      state.billingStatuses = value
    },
    bookingTags(state, value) {
      state.bookingTags = value
    },
    statuses(state, value) {
      state.statuses = value
    },
    outcomes(state, value) {
      state.outcomes = value
    },
    states(state, value) {
      state.states = value
    },
    salesStages(state, value) {
      state.salesStages = value
    },
    contractStatuses(state, value) {
      state.contractStatuses = value
    },
    contactTypes(state, value) {
      state.contactTypes = value
    },
    emailTemplates(state, value) {
      state.emailTemplates = value
    },
    lostReasons(state, value) {
      state.lostReasons = value
    },
    priceChangeReasons(state, value) {
      state.priceChangeReasons = value
    },
    bookingSources(state, value) {
      state.bookingSources = value
    },
    billingTransactionMethods(state, value) {
      state.billingTransactionMethods = value
    },
    contractTemplates(state, value) {
      state.contractTemplates = value
    },
    closePercentages(state, value) {
      state.closePercentages = value
    },
    taxRates(state, value) {
      state.taxRates = value
    },
    occasions(state, value) {
      state.occasions = value
    },
    durations(state, value) {
      state.durations = value
    },
    budgets(state, value) {
      state.budgets = value
    },
    notificationTypes(state, value) {
      state.notificationTypes = value
    },
    users(state, value) {
      state.users = value
    },
    user(state, value) {
      state.user = value
    },
    logs(state, value) {
      state.logs = value
    },
    reminderDate(state, date) {
      state.reminderDate = date
    },
    transactionalEmails(state, value) {
      state.transactionalEmails = value
    },
    updateBookingFilters(state, filters) {
      state.bookingFilters = filters
    },
    updateIsProcessing(state, isProcessing) {
      state.isProcessing = isProcessing
    },
    reset(state) {
      // reset store when load new booking.
      state.reservations = []
    },
    reservations(state, reservationsData) {
      // Remove reservations
      const reservationObjects = state.reservations.filter((reservation) =>
        reservationsData.some((reservationData) => reservationData.data.id === reservation.id),
      )
      reservationsData.forEach((reservationData) => {
        let existingReservation = reservationObjects.find(
          (referenceObject) => referenceObject.id === reservationData.data.id,
        )
        if (existingReservation === undefined) {
          state.reservations.push(new Reservation(reservationData, store))
        } else {
          existingReservation.load(reservationData)
        }
      })
    },
  },
  actions: {
    handleErrors(context, errors) {
      //do something
    },

    processAdminData({ commit, state }, data) {
      const statePropertyNames = Object.keys(state)

      for (const propertyName of statePropertyNames) {
        if (data.hasOwnProperty(propertyName)) {
          commit(propertyName, data[propertyName])
        }
      }
    },

    clearNotification({ dispatch, state }, notification) {
      const endpoint = route("admin.bookings.booking.notifications.booking-notification.clear", {
        booking: state.booking,
        notification: notification,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    refresh({ dispatch, state }) {
      const endpoint = route("admin.bookings.booking.refresh", {
        booking: state.booking,
      })

      return dispatch("doAction", { endpoint: endpoint })
    },

    setAutomaticEmails({ dispatch, state }, automatic_emails) {
      const endpoint = route("admin.bookings.booking.set-automatic-emails", {
        booking: state.booking,
        automatic_emails,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    assign({ dispatch, state }) {
      const endpoint = route("admin.bookings.booking.take", {
        booking: state.booking,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    release({ dispatch, state }) {
      const endpoint = route("admin.bookings.booking.release", {
        booking: state.booking,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    transfer({ dispatch, state }, user_id) {
      const endpoint = route("admin.bookings.booking.transfer", {
        booking: state.booking,
        user_id: user_id,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    switchServe({ dispatch, state }, serve) {
      const endpoint = route("admin.bookings.booking.switch-serve", {
        booking: state.booking,
        serve: serve,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    setSalesStage({ dispatch, state }, stage) {
      const endpoint = route("admin.bookings.booking.set-sales-stage", {
        booking: state.booking,
        stage: stage,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },
    setTags({ dispatch, state }, tags) {
      const endpoint = route("admin.bookings.booking.update-tags", {
        booking: state.booking,
        tags,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    setBookingSource({ dispatch, state }, source) {
      const endpoint = route("admin.bookings.booking.set-booking-source", {
        booking: state.booking,
        source_id: source,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    setClosePercentage({ dispatch, state }, percent) {
      const endpoint = route("admin.bookings.booking.set-close-percentage", {
        booking: state.booking,
        close_percentage: percent,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    setReminderDate({ dispatch, getters, state }, when) {
      const endpoint = route("admin.bookings.booking.set-reminder-date", {
        booking: state.booking,
        when: when,
        timezone: getters.timeZone,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    setBudget({ dispatch, getters, state }, amount) {
      const endpoint = route("admin.bookings.booking.set-budget", {
        booking: state.booking,
        amount,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    // Reject a booking
    rejectReservation({ dispatch, getters, state }, lost_reason_id) {
      const endpoint = route("admin.bookings.booking.reject", {
        booking: state.booking,
        lost_reason_id,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    // Save an internal note
    addNote({ dispatch, state }, data) {
      const endpoint = route("admin.notes.store-note", {
        type: "booking",
        id: state.booking.id,
        ...data,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    // Delete an internal note
    deleteNote({ dispatch, commit, state }, note) {
      const endpoint = route("admin.notes.note.delete", {
        note: note.id,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    // Update the order of internal notes
    sequenceNotes({ dispatch, commit, state }, notes) {
      const endpoint = route("admin.notes.sequence-notes")
      return dispatch("doAction", { endpoint: endpoint, data: { notes: notes } })
    },

    finaliseBilling({ dispatch, state }, data) {
      const endpoint = route("admin.bookings.booking.finalise-billing", {
        booking: state.booking,
        ...data,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    addReservation({ dispatch, state }) {
      const endpoint = route("admin.bookings.booking.add-reservation", {
        booking: state.booking,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    cancel({ dispatch, state }, data) {
      const endpoint = route("admin.bookings.booking.cancel", {
        booking: state.booking,
        ...data,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    setOccasion({ dispatch, state }, data) {
      const endpoint = route("admin.bookings.booking.set-occasion", {
        booking: state.booking,
        ...data,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    sendInvoice({ dispatch, state }, data) {
      const endpoint = route("admin.bookings.booking.billing-documents.invoices.invoice.send-invoice", {
        booking: state.booking,
        invoice: data.invoice,
        email_template_id: data.email_template_id,
        message: data.message,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    markAsPaid({ dispatch, state }, data) {
      let endpoint = ""
      if (data.document.type === "invoice") {
        endpoint = route("admin.bookings.booking.billing-documents.invoices.invoice.mark-as-paid", {
          booking: state.booking,
          invoice: data.document,
          transaction_method: data.transaction_method,
          amount: data.amount,
        })
      } else {
        endpoint = route("admin.bookings.booking.billing-documents.refunds.refund.mark-as-paid", {
          booking: state.booking,
          refund: data.document,
          transaction_method: data.transaction_method,
          amount: data.amount,
        })
      }

      return dispatch("doAction", { endpoint: endpoint })
    },

    cancelBillingDocument({ dispatch, state }, data) {
      let endpoint = ""
      if (data.document.type === "invoice") {
        endpoint = route("admin.bookings.booking.billing-documents.invoices.invoice.cancel", {
          booking: state.booking,
          invoice: data.document,
          reason: data.reason,
        })
      } else {
        endpoint = route("admin.bookings.booking.billing-documents.refunds.refund.cancel", {
          booking: state.booking,
          refund: data.document,
          reason: data.reason,
        })
      }

      return dispatch("doAction", { endpoint: endpoint })
    },

    cancelRefund({ dispatch, state }, data) {
      const endpoint = route("admin.bookings.booking.billing-documents.refunds.refund.cancel", {
        booking: state.booking,
        refund: data.refund,
        reason: data.reason,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    refundInvoice({ dispatch, state }, data) {
      const endpoint = route("admin.bookings.booking.billing-documents.invoices.invoice.refund", {
        booking: state.booking,
        invoice: data.invoice_id,
      })
      dispatch("doAction", {
        endpoint: endpoint,
        data: {
          amount: data.amount,
        },
      })
    },

    processRefund({ dispatch }, data) {
      const endpoint = route("admin.bookings.booking.billing-documents.refunds.refund.pay", {
        refund: data.refund_id,
      })
      dispatch("doAction", {
        endpoint: endpoint,
      })
    },

    createContract({ dispatch, state }, contractTemplateId) {
      const endpoint = route("admin.bookings.booking.contracts.create", {
        booking: state.booking,
        contract_template_id: contractTemplateId,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    updateContract({ dispatch, state }, contract) {
      const endpoint = route("admin.bookings.booking.contracts.contract.save", {
        booking: state.booking,
        contract: contract,
        document: contract.document,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    sendForSigning({ dispatch, state }, contract) {
      const endpoint = route("admin.bookings.booking.contracts.contract.send-for-signing", {
        booking: state.booking,
        contract: contract,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    markAsSigned({ dispatch, state }, contract) {
      const endpoint = route("admin.bookings.booking.contracts.contract.mark-as-signed", {
        booking: state.booking,
        contract: contract,
        asset_id: contract.signed_asset_id,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    deleteContract({ dispatch, state }, contract) {
      const endpoint = route("admin.bookings.booking.contracts.contract.delete-contract", {
        booking: state.booking,
        contract: contract,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    // Save booking contact
    updateOrCreateBookingContact({ dispatch, state }, data) {
      const endpoint = route("admin.bookings.booking.add-booking-contact", {
        booking: state.booking,
        ...data,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    // get All Logs
    logs({ dispatch, state }) {
      const endpoint = route("admin.bookings.booking.log", {
        booking: state.booking,
      })
      return dispatch("doAction", { endpoint: endpoint })
    },

    // Actually make the backend call
    async doAction({ dispatch, state, commit }, { endpoint, data }) {
      commit("updateIsProcessing", true)

      return await axios
        .post(endpoint, data)
        .then((response) => {
          dispatch("processAdminData", response.data)
          commit("updateIsProcessing", false)
          return response.data
        })
        .catch((error) => {
          commit("updateIsProcessing", false)
          dispatch("handleErrors", error)
          throw error
        })
        .finally(() => {
          commit("updateIsProcessing", false)
        })
    },
  },
})

export default store
